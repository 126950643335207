<label *ngIf="label" [for]="labelId">{{ label }}</label>
<div class="position-relative {{ validation ? 'validation' : '' }}">
  <ng-select
    #select
    (change)="onChange($event)"
    [class]="
      (checkbox ? 'withCheckbox' : 'withoutCheckbox') +
      ' ' +
      (darkMode === true ? 'dark' : '') +
      ' ' +
      (clearInputX === true ? 'clearInputX' : '')
    "
    [closeOnSelect]="false"
    [closeOnSelect]="closeOnSelect"
    [formControl]="control"
    [items]="items"
    [labelForId]="labelId"
    [multiple]="multiple"
    [ngClass]="{
      'disable-arrow disable-dropdown disable-clear-all': readonly === true
    }"
    [placeholder]="placeholder"
    [searchable]="searchable"
    [selectableGroupAsModel]="true"
    [selectableGroup]="false"
    bindLabel="displayValue"
    [compareWith]="compareWith ? compareFn : null"
    bindValue="id"
    groupBy="category"
    appendTo="body"
    [clearable]="clearable"
    [loading]="loading"
  >
    <ng-template let-clear="clear" let-item="item" ng-label-tmp>
      <span [title]="item.displayValue" class="ng-value-label">{{
        item.displayValue
      }}</span>
    </ng-template>
    <ng-template *ngIf="searchable == null ? false : searchable" ng-header-tmp>
      <input
        (input)="select.filter($event.target.value)"
        [readonly]="readonly"
        placeholder="Search"
        style="width: 100%; line-height: 24px"
        type="text"
      />
    </ng-template>
    <ng-template
      let-index="id"
      let-item="item"
      let-item$="item$"
      ng-optgroup-tmp
    >
      {{ item.category | uppercase }}
    </ng-template>
    <ng-template let-index="id" let-item="item" let-item$="item$" ng-option-tmp>
      <input
        *ngIf="checkbox"
        id="item-{{ index }}"
        type="checkbox"
        [ngModel]="item$.selected"
        [ngModelOptions]="{ standalone: true }"
        class="form-check-input me-2"
      />
      <span [title]="item.displayValue">{{ item.displayValue }}</span>
    </ng-template>
  </ng-select>
  <app-dc-control-validation
    *ngIf="control.touched && validation"
    [status]="control.invalid ? 'invalid' : 'valid'"
    class="{{
      control.hasError('required') && control.touched ? 'required' : ''
    }} "
    body="{{ control.hasError('required') ? 'Required field' : '' }}{{
      control.hasError('ruleValidator') ? 'Duplicate rules found' : ''
    }}"
  ></app-dc-control-validation>
</div>
